<script>
import Form from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const rule = await this.getFormRule('bs_direct_system_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.get('/kms/kmstenantrydirectcustomerorg/query', { id: this.formConfig.id }).then((res) => {
        this.setValue({
          ...res.result,
        });
        this.row = res.result;
      });
    }
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'directId') {
        // 直营体系名称远程搜索
        item.restful = '/kms/kmsAdmin/tenantry/direct/list';
        item.headers = { functionCode: 'bs_direct_system_list' };
        item.props = {
          ...item.props,
          loading: false,
          clearable: true,
          filterable: true,
          remote: true,
          remoteParams: 'directName',
        };
        item.optionsKey = { label: 'directName', value: 'directId' };
        item.refresh = true;
      } else if (item.field === 'customerOrgCode') {
        // 客户组织名称远程搜索
        item.restful = '/mdm/mdmCusOrgController/select';
        item.headers = { functionCode: 'bs_direct_system_list' };
        item.props = {
          ...item.props,
          loading: false,
          clearable: true,
          filterable: true,
          remote: true,
          remoteParams: 'customerOrgName',
        };
        item.optionsKey = { label: 'customerOrgName', value: 'customerOrgCode' };
        item.refresh = true;
      }
      return item;
    },
    formComplete() {
      const directId = this.getRule('directId');
      const customerOrgCode = this.getRule('customerOrgCode');
      // 获取直营体系名称
      directId.on.change = (e) => {
        const selectedData = directId.options.find((item) => item.directId === e);
        this.setValue({
          bsDirectSystemName: selectedData.directName,
        });
      };
      // 获取客户组织数据
      customerOrgCode.on.change = (e) => {
        const selectedCusData = customerOrgCode.options.find((item) => item.customerOrgCode === e);
        this.setValue({
          customerOrgId: selectedCusData.id,
          customerOrgName: selectedCusData.customerOrgName,
        });
      };
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        ...this.row,
        ...formData,
      };
      request.post(this.formConfig.id ? ('/kms/kmstenantrydirectcustomerorg/update') : ('/kms/kmstenantrydirectcustomerorg/save'), param, { headers: { functionCode: 'bs_direct_system_list' } }).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
