<script>
import TablePage from '@/found/components/table_page';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {

    };
  },
  async created() {
    await this.getConfigList('bs_direct_system_list');
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'Form';
      if (val.code === 'add') {
        // 新增
        this.formConfig = {
          type: val.code,
        };
        this.modalConfig.title = '新增';
        this.modalConfig.width = '50%';
        this.openModal();
      } else if (val.code === 'edit') {
        // 编辑
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '50%';
        this.openModal();
      }
    },
  },
};
</script>
